<script>
import InputCompletion from "@/views/components/global/InputCompletion";
import common from "@/mixins/common";

export default {
  name: "noteCompletion",
  components: {InputCompletion},
  mixins: [common],
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
  render(createElement, context) {
    let self = this
    return createElement(
        'div',
        [
          createElement('p', {
            attrs: { 'author-class': 'subRubric cbview' },
            domProps: {innerHTML: this.part.note}
          }),
          createElement('p', [
            createElement('p', [
              this.questions.map(q => {
                let questList = q.content.split('###INPUT###').map((sub_q, inx) => {
                  return inx === q.content.split('###INPUT###').length - 1 ?
                      [ createElement('span', sub_q) ] :
                      [ createElement('span', sub_q),
                        createElement ('span', [
                          createElement ('input-completion', {
                            props: {
                              'in-focus': self.focusQuestNo === q.id,
                              question: q
                            }
                          })
                        ]),
                      ]
                })
                questList.push (
                    createElement('br'),
                    createElement('br')
                )
                return questList;
              })
            ]),
          ])
        ]
    )
  }
}
</script>

<style scoped>

</style>
