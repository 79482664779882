<template>
  <component :is="processedHtml" :focus-quest-no="focusQuestNo" :questions="questions"></component>
</template>

<script>

export default {
  name: "PartLeft",
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
          part_content: ''
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default() {
        return 1;
      }
    }
  },
  watch: {
    'part.no' () {
      localStorage.setItem('ScrollTop', 0)
    }
  },
  computed: {
    processedHtml() {
      let questionContent = this.part.part_content

      this.questions.forEach((q, ind) => {
        questionContent = questionContent.replace(
            '###INPUT###',
            '<HeadingCompletionDrag ' +
                          ':in-focus="focusQuestNo === questions[' + ind + '].id" ' +
                          ':question="questions[' + ind + ']" >' +
                       '</HeadingCompletionDrag>')
      })

      return {
        name: 'ProcessHtml',
        template: '<div author-class="left" class="scroll-container" id="part-left" @scroll="handleScroll">' + questionContent + '</div>',
        props: {
          questions: {
            type: Array,
            default() {
              return []
            }
          },
          focusQuestNo: {
            type: Number,
            default() {
              return 1;
            }
          }
        },
        created() {
          this.$nextTick(() => {
            this.avoidScroll()
          })
        },
        methods: {
          avoidScroll() {
            document.getElementById('part-left').scrollTop = Number(localStorage.getItem('ScrollTop') || '0')
          },
          handleScroll () {
            localStorage.setItem('ScrollTop', String(document.getElementById('part-left').scrollTop))
          }
        },
      }
    }
  }
}
</script>

<style scoped>

</style>
