<template>
  <div role="main" id="highlightable-content">
    <div id="itemBody" role="application" class="itemBody nontest" data-type="finished">
      <div class="box1">
        <div class="title hasicon">
          <img src="@assets/images/main/testEndTime.png" width="48" height="48" alt=""/>
          Test ended
        </div>
        <div class="content">
          <p>Your test has finished.</p>
          <p>All of your answers have been stored.</p>
          <p>Please wait for further instructions.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestEnded"
}
</script>

<style scoped>

</style>
