<template>
  <div author-class="container generic">
    <p author-class="subRubric cbview" v-html="part.note"/>
    <div author-class="inlineGapText split-none presentation-ortn-vertical"
         class="gapMatchInteraction blockInteraction interaction">
      <div author-class="left" class="gapsStimulus">
        <component :is="processedHtml" :focus-quest-no="focusQuestNo" :questions="questions"></component>
      </div>
      <div author-class="right" id="choices" class="gapChoices">
        <ul class="clearfix drops">
          <template v-for="c in questions[0].choice">
            <li :key="c.id"
                :id="`choice_` + c.id"
                :class="{ hide : selectedAns.includes(c.id)}"
                class="gapText gapChoice associableChoice choice ui-draggable"
                tabindex="4" role="option" draggable="true" aria-grabbed="false"> {{ c.label }}
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import InputCompletionDrag from "@/views/components/global/InputCompletionDrag";
import common from "@/mixins/common";
import Vue from "vue";
import {bus} from "@/main";

Vue.component('InputCompletionDrag', InputCompletionDrag)
export default {
  name: "matchingSentenceEnding",
  components: {InputCompletionDrag},
  mixins: [common],
  data() {
    return {
      selectedAns: []
    }
  },
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
  computed: {
    processedHtml() {
      let questionContent = this.questions[0].content

      this.questions.forEach((q, ind) => {
        questionContent = questionContent.replace('###INPUT###', '<InputCompletionDrag :in-focus="focusQuestNo === questions[' + ind + '].id" :question="questions[' + ind + ']" ></InputCompletionDrag>')
      })

      return {
        name: 'ProcessHtml',
        template: '<div>' + questionContent + '</div>',
        props: {
          questions: {
            type: Array,
            default() {
              return []
            }
          },
          focusQuestNo: {
            type: Number,
            default() {
              return 1;
            }
          }
        }
      }
    }
  },
  created() {
    this.questions.map(q => q.answerId = '')
    this.initDrag()
    bus.$on('drop', (arg) => {
      this.handleDropAns(arg)
    })
  },
  methods: {
    initDrag() {
      this.$nextTick(() => {
        this.questions[0].choice.forEach(c => {
          let id = '#choice_' + c.id
          $(id).draggable({
            revert: "invalid",
            cursor: "move",
            helper: "clone",
            start: function (e, ui) {
              $(ui.helper).addClass("gapTextDragger limit-width");
            }
          });
        })
      })
    },
    handleDropAns(arg) {
      let selectId = Number(arg.select.replace('choice_', ''))
      if (selectId) {
        this.selectedAns.push(selectId)
      }

      if (arg.cancel) {
        let cancelId = Number(arg.cancel.replace('choice_', ''))
        this.selectedAns = this.selectedAns.filter(v => v !== cancelId)
      }
    }
  }
}
</script>

<style scoped>
.limit-width{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
