<template>
  <div role="navigation" style=""><h1 class="reader-only">Navigation</h1>
    <div :class="navigation_class" id="navigation-bar">
      <div class="testPart">
        <ul>
          <li class="assessmentSection" ><span class="section-label">Part 1</span>
            <ul>
              <li class="assessmentItemRef" >
                <template v-for="no in questions.length">
                  <button @click="changeQuest(no)" :state="state(no)">
                    <span class="question-label">Question </span>
                    <span class="question-number">{{no}} </span>
                  </button>
                </template>
              </li>
            </ul>
          </li>
        </ul>
        <div class="views">
          <button title="switch view to navigation summary" class="minimise" @click="() => this.navigation_class='minimised'">
            <span>navigation summary</span> 
          </button>
          <button title="switch view to navigation details" class="maximise" @click="() => this.navigation_class='maximised'">
            <span>navigation details</span> 
          </button>
        </div>
      </div>
    </div>
    <label id="review-checkbox">
      <input function="mark-for-review" type="checkbox" v-model="review" @click="markForReview"/>Review
    </label>
    <button title="Previous Question" function="previous" :class="{hide : focusQuestNo === 1}" @click="changeQuest(focusQuestNo - 1)">
      <span class="reader-only">Previous Question</span>
    </button>
    <button title="Next Question" href="" function="next" :class="{hide : focusQuestNo === questions.length}" @click="changeQuest(focusQuestNo + 1)">
      <span class="reader-only">Next Question</span>
    </button>
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  name: "navigation",
  props: {
    questions: {
      type: Array,
      default: [{
        answer: ''
      }]
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      navigation_class: 'maximised',
      review: false,
      mark_no: new Set()
    }
  },
  mixins: [common],
  watch: {
    focusQuestNo () {
      this.review = this.mark_no.has(this.focusQuestNo)
    }
  },
  methods: {
    state(no) {
      let state = ''
      state = no === this.focusQuestNo ? `current` : ``
      state += this.mark_no.has(no) ? ` marked-for-review` : ``
      state += this.questions[no-1].answer.length > 0? ` completed` : ``
      return state
    },
    markForReview() {
      if (!this.review) {
        this.mark_no.add(this.focusQuestNo)
      } else {
        this.mark_no.delete(this.focusQuestNo)
      }
    }
  }
}
</script>

<style scoped>
#review-checkbox input[function~=mark-for-review] {
  margin-right: 3px;
}
</style>
