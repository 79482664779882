<template>
  <div author-class="container generic">
    <p author-class="subRubric cbview" v-html="part.note"/>

    <div author-class="tableMultiEntry cbview-width-95"
         class="tableMatchInteraction multi blockInteraction interaction" maxAssociations="6" shuffle="false">
      <table style="width:95%;" v-if="questions.length">
        <thead>
        <tr>
          <th></th>
          <template v-for="choice in ['A','B','C']">
            <th><p><strong>{{ choice }}</strong></p></th>
          </template>
        </tr>
        </thead>
        <tbody>
        <template v-for="q in questions">
          <tr style="height: 29px;" :key="q.id" @click="changeQuest(q.id)">
            <td :class="{'inFocus' : focusQuestNo === q.id}">
              <span class="questionNumber">{{ q.id }}</span>
              <p>{{ q.content }}</p></td>
            <template v-for="choice in ['A','B','C']">
              <td tabindex="0" @focus="changeQuest(q.id)" @click="() => q.answer = choice"
                  :class="{'inFocus' : focusQuestNo === q.id, 'checked' : q.answer === choice}">
                <input class="simpleAssociableChoice associableChoice choice" style="display:none" type="checkbox"/> 
              </td>
            </template>
          </tr>
        </template>
        </tbody>
      </table>
    </div>

    <table >
      <tbody>
      <tr>
        <td colspan="2"><p><strong><span author-class="fs12pt">List of political units<br/></span></strong></p>
        </td>
      </tr>
      <template v-for="u in ['A','B','C']">
        <tr>
          <td><p><strong>{{u}}</strong></p></td>
          <td><p>{{part.politicalUnits[u]}}</p></td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  name: "matchingFeatures",
  mixins: [common],
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
          politicalUnits: {}
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
}
</script>

<style scoped>

</style>
