<template>
  <div>
    <template v-for="note in notes">
      <note :key="note.id" :note="note"/>
    </template>
  </div>
</template>
<script>
import Note from "@/views/components/global/Note";

export default {
  name: "RightClick",
  components: {Note},
  data() {
    return {
      highlighter: null,
      noteIdMax: 0,
      notes: []
    }
  },
  created() {
    this.$nextTick(() => {
      this.initRangy()
      this.initContextMenu()
    })
  },
  methods: {
    initRangy() {
      this.$rangy.init();

      this.highlighter = this.$rangy.createHighlighter();

      this.highlighter.addClassApplier(this.$rangy.createClassApplier("ylw-hglted", {
        ignoreWhiteSpace: true,
        tagNames: ["span", "a"]
      }));
    },
    initContextMenu() {
      let thiz = this
      $.contextMenu({
        selector: '#highlightable-content',
        className: 'context-menu-list context-menu-root',
        items: {
          "highlight": {
            name: "Highlight",
            className: "context-menu-item icon icon-highlight show",
            callback: () => thiz.highlightText()
          },
          "note": {
            name: "Notes",
            className: "context-menu-item icon icon-edit",
            callback: (key, options) => thiz.createNote(key, options)
          },
          "clear": {
            name: "Clear",
            className: "context-menu-item icon icon-clear show",
            callback: (key, options) => thiz.removeHighlightFromSelectedText(key, options),
            title : "Clears this highlighting"
          },
          "clearAll": {
            name: "Clear all",
            className: "context-menu-item icon icon-clearAll show",
            callback: () => thiz.removeAllHighlights(),
            title : "Clears all highlighting on this page"
          },
        },
        events: {
          show: (opt) => thiz.checkSelection(opt)
        }
      })
    },
    highlightText() {
      this.highlighter.highlightSelection("ylw-hglted");
    },
    removeHighlightFromSelectedText(key, options) {
      const ele = this.$rangy.getSelection().getRangeAt(0)
      const sub_ele = $(ele.endContainer)[0].previousElementSibling
      if (sub_ele) {
        let id = Number(sub_ele.id.replace("note_",""))
        this.notes = this.notes.filter(n => n.id !== id)
      }
      this.highlighter.unhighlightSelection();
    },
    removeAllHighlights() {
      this.highlighter.removeAllHighlights()
      this.notes = []
    },
    createNote(key, options) {
      const noteId = this.noteIdMax
      this.noteIdMax ++
      this.highlighter.highlightSelection("ylw-hglted");

      const ele = this.$rangy.getSelection().getRangeAt(0)
      $(ele.endContainer.parentNode).prepend('<span class="notesIcon" id="note_' + noteId + '"></span>');
      const noteEle = document.getElementById("note_" + noteId);
      noteEle.parentElement.addEventListener("click", () => this.clickShowNote(noteId), false)

      const pos = options.$menu.offset()
      this.notes.push({
        id: noteId,
        isShow: true,
        top: pos.top,
        left: pos.left
      })
    },
    clickShowNote(id) {
      this.notes.filter(n => n.id === id)[0].isShow = true
    },
    checkSelection(opt) {
      screen.sel = this.$rangy.getSelection();
      if (screen.sel.rangeCount === 0 || screen.sel.isCollapsed) {
        opt.items.highlight.disabled = true;
        var range = screen.sel.getRangeAt(0)
        if ($(range.startContainer.parentNode).hasClass('ylw-hglted')) {
          opt.items.note.disabled = false;
          opt.items.clear.disabled = false;
          opt.items.clearAll.disabled = false;

        } else {
          opt.items.note.disabled = true;
          opt.items.clear.disabled = true;
          opt.items.clearAll.disabled = true;
        }
      } else {
        if (this.checkValidSelection(screen.sel)) {
          opt.items.highlight.disabled = false;
          opt.items.note.disabled = false;
        } else {
          opt.items.highlight.disabled = true;
          opt.items.note.disabled = true;
        }
        opt.items.clear.disabled = true;
        opt.items.clearAll.disabled = true;

      }
      if (opt.items.highlight.disabled && opt.items.note.disabled && opt.items.clear.disabled && opt.items.clearAll.disabled) {
        opt.$menu.addClass('hidden');
      } else {
        opt.$menu.removeClass('hidden');
      }
    },
    checkValidSelection(sel) {
      var range = sel.getRangeAt(0);
      // const sub_ele_before = $(window.getSelection().baseNode.parentNode)[0].className
      // const sub_ele_after = window.getSelection().baseNode.previousSibling
      // const sub_ele = window.getSelection().baseNode.nextSibling
      // console.log($(window.getSelection().baseNode))
      // // if (sub_ele_after && $(sub_ele_after)[0].className === 'ylw-hglted') {
      // //   return false
      // // }
      // // if (sub_ele && $(sub_ele)[0].className === 'ylw-hglted') {
      // //   return false
      // // }
      // // if (sub_ele_before === 'ylw-hglted') {
      // //   return false
      // // }
      return ($(range.startContainer.parentNode).closest('#highlightable-content').length > 0) &&
          ($(range.endContainer.parentNode).closest('#highlightable-content').length > 0);
    }
  }
}
</script>

<style scoped>

</style>
