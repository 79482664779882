<template>
  <div>
    <p author-class="subRubric cbview" v-html="part.note"/>
    <component :is="processedHtml" :focus-quest-no="focusQuestNo" :questions="questions" ></component>
  </div>
</template>

<script>
import InputCompletion from "@/views/components/global/InputCompletion";
import common from "@/mixins/common";
import Vue from "vue";
Vue.component('InputCompletion', InputCompletion)
export default {
  name: "sentenceCompletion",
  components: {InputCompletion},
  mixins: [common],
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
  computed: {
    processedHtml () {
      let questionContent = this.questions[0].content

      this.questions.forEach((q,ind) => {
        questionContent = questionContent.replace('###INPUT###', '<InputCompletion :in-focus="focusQuestNo === questions[' + ind + '].id" :question="questions[' + ind + ']" ></InputCompletion>')
      })

      return {
        name: 'ProcessHtml',
        template: '<div>' + questionContent + '</div>',
        props: {
          questions: {
            type: Array,
            default () {
              return []
            }
          },
          focusQuestNo: {
            type: Number,
            default() {
              return 1;
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
