<template>
  <div class="note"
       :id="'note_main_' + $vnode.key"
       style="z-index: 1"
       :class="[ note.isShow ? 'show' : 'hide']">
    <div class="close" @click="() => this.note.isShow = false"></div>
    <div class="draghandle" @mousedown="handleMouseDown"></div>
    <div class="edit">
      <div class="mainText" contenteditable="true">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Note",
  data () {
    return {
      ele: null,
      startX: 0,
      startY: 0
    }
  },
  props: {
    note: {
      type: Object,
      default: {
        isShow: true,
        top: 0,
        left: 0
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.ele = document.getElementById('note_main_' + this.$vnode.key)
      this.ele.style.top = this.note.top + 'px'
      this.ele.style.left = this.note.left + 'px'
    })
  },
  methods: {
    handleMouseUp (event) {
      document.removeEventListener('mousemove', this.handleMouseMove, true);
      document.removeEventListener('mouseup', this.handleMouseUp, true);
    },
    handleMouseDown (event) {
      this.startX = event.clientX - this.ele.style.left.replace('px','')
      this.startY = event.clientY - this.ele.style.top.replace('px','')
      document.addEventListener('mousemove', this.handleMouseMove, true);
      document.addEventListener('mouseup', this.handleMouseUp, true);
    },
    handleMouseMove (event) {
      this.ele.style.top = event.clientY - this.startY + 'px'
      this.ele.style.left = event.clientX - this.startX + 'px'
    }
  }
}
</script>

<style scoped>

</style>
