<template>
  <span
      :class="{'inFocus' : inFocus, attempted : question.answer.length > 0 }"
      @focus="changeQuest(question.id)"
      @click="changeQuest(question.id)"
      v-model="question.answer"
      :id="'ans_' + question.id"
      class="gap associableChoice choice ui-droppable" tabindex="3"
      aria-selected="false">
    <ins v-if="question.answer"
         :id="'selected_ans_' + question.id"
         class="gapText gapChoice associableChoice choice ui-draggable"
         draggable="true" aria-grabbed="false">{{question.answer}}</ins>
    <span class="questionNumber">{{ question.id }}</span>
  </span>
</template>

<script>
import common from "@/mixins/common";
import {bus} from "@/main";

export default {
  name: "InputCompletionDrag",
  mixins: [common],
  props: {
    question: {
      type: Object,
      default() {
        return {
          id: 23,
          answer: '',
          answerId: ''
        }
      }
    },
    inFocus: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  created() {
    if (this.inFocus) {
      const element = document.getElementById('id_' + this.question.id)
      if (element) element.focus()
    }
    this.initDrag()
  },
  watch: {
    inFocus(nVal, oVal) {
      if (this.inFocus) {
        const element = document.getElementById('id_' + this.question.id)
        if (element) element.focus()
      }
    }
  },
  methods: {
    initDrag() {
      const thiz = this
      this.$nextTick(function () {
          let id = '#ans_' + thiz.question.id
          $(id).droppable({
            hoverClass: 'availableTarget',
            drop: function( event, ui ) {
              thiz.handleSelectedAns(
                  id,
                  ui.draggable.prop('id'),
                  ui.draggable.prop('innerText')
              )
            }
          });
          $(id).draggable({
            cursor: "move",
            helper: "clone",
            start: function(e, ui)
            {
              $(ui.helper).addClass("gapTextDragger limit-width");
            },
            stop: function (e, ui) {
              thiz.handleCancelAns()
            }
          });
      })

    },
    handleSelectedAns(questId, id, ans) {

      const arg = {
        select: id,
        cancel: this.question.answerId
      }

      this.question.answer = ans
      this.question.answerId = id

      bus.$emit('drop', arg)

      questId = Number(questId.replace("#ans_",""))
      this.changeQuest(questId)

    },
    handleCancelAns() {
      const cancelAnsId = this.question.answerId
      this.question.answer = ''
      this.question.answerId = ''
      bus.$emit('drop', {
        select: '',
        cancel: cancelAnsId
      })
    }
  }
}
</script>

<style scoped>
span.choice {
  padding: 0 75px;
}
span.choice.attempted {
  padding: 0 !important;
}
.limit-width{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
