<template>
  <span
        :class="{'inFocus' : inFocus, attempted : question.answer.length > 0}"
        class="textEntryInteraction inlineInteraction stringInteraction interaction">
            <span class="questionNumber" >{{ question.id }}</span>
            <input @focus="changeQuest(question.id)"
                   @click="changeQuest(question.id)"
                   v-model="question.answer"
                   :id="'id_' + question.id"
                   author-class="presentation-expandable"
                   expectedLength="15"
                   size="15"
                   type="text"
                   :style="{width: (question.answer.length * 7.) + 'px'}"
                   style="min-width: 134px; max-width: 482px"
                   state=""/>
  </span>
</template>

<script>
import common from "@/mixins/common";

export default {
  name: "InputCompletion",
  mixins: [common],
  props: {
    question: {
      type: Object,
      default () {
        return {
          id: 23,
          answer: ''
        }
      }
    },
    inFocus: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  mounted() {
    if (this.inFocus) {
      const element = document.getElementById('id_' + this.question.id)
      if (element) element.focus()
    }
  },
  watch: {
    inFocus(nVal, oVal) {
      if (this.inFocus) {
        const element = document.getElementById('id_' + this.question.id)
        if (element) element.focus()
      }
    }
  },
}
</script>

<style scoped>

</style>
