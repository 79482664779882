<template>
  <div author-class="container generic">
    <p author-class="subRubric cbview" v-html="part.note"/>
    <div author-class="container accordionChoice" class="question">
      <div class="questionChoice ui-accordion ui-widget ui-helper-reset inFocus" role="tablist">
        <h3 @click="changeQuest(questions[0].id)"
            @focus="changeQuest(questions[0].id)"
            :id=questions[0].id
            @mouseover="hover(questions[0].id)"
            @mouseout="out(questions[0].id)"
            class="interaction ui-accordion-header ui-helper-reset ui-state-default ui-corner-all"
            tabindex="0" role="tab"
            :class="{'inFocus' : focusQuestNo === questions[0].id}"
            :aria-selected="focusQuestNo === questions[0].id" :aria-expanded="focusQuestNo === questions[0].id"><span
            class="questionNumber">{{ questions.map(q => q.id).join(" - ") }}</span>
          <p class="prompt" style="padding: 0 0 0 25px">{{ questions[0].content }}</p>
        </h3>
        <div author-class="libs-itemtype-0019"
             class="choiceInteraction blockInteraction interaction ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom ui-accordion-content-active inFocus"
             hasPrompt="true" maxChoices="2" role="tabpanel" :aria-hidden="focusQuestNo !== questions[0].id">
          <ul shuffle="false">
            <template v-for="(choice, ind) in questions[0].choice">
              <li class="simpleChoice choice">
                <label>
                  <input v-model="selectedChoice" type="checkbox" :value="choice.id" :disabled="selectedChoice.length >= questions.length && selectedChoice.indexOf(choice.id) === -1"/>
                  <p>{{choice.label}}</p>
                </label>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  name: "multipleChoice",
  mixins: [common],
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
  watch: {
    selectedChoice () {
      this.questions.forEach((q,ind) => {
        q.answer = this.selectedChoice[ind] ? this.selectedChoice[ind].toString() : ''
      })
    }
  },
  data () {
    return {
      selectedChoice: []
    }
  },
  methods: {
    hover(id) {
      const element = document.getElementById(id)
      if (element) {
        let clazz = element.className
        if (!clazz.includes("ui-state-hover")) element.className += " ui-state-hover"
      }
    },
    out(id) {
      const element = document.getElementById(id)
      if (element) {
        let clazz = element.className
        clazz = clazz.replace(" ui-state-hover", "")
        element.className = clazz
      }
    },
  }
}
</script>

<style>
h3 + div[role="tabpanel"] {
  -webkit-transition: max-height .3s;
  -moz-transition: max-height .3s;
  -ms-transition: max-height .3s;
  -o-transition: max-height .3s;
  transition: max-height .3s;
  max-height: 0;
}

h3.inFocus + div[role="tabpanel"] {
  -webkit-transition: max-height .7s;
  -moz-transition: max-height .7s;
  -ms-transition: max-height .7s;
  -o-transition: max-height .7s;
  transition: max-height .7s;
  max-height: 200px;
}
</style>
