<template>
  <div author-class="container generic">
    <p author-class="subRubric cbview" v-html="part.note"/>

    <div author-class="tableMultiEntry cbview-width-95"
         class="tableMatchInteraction multi blockInteraction interaction" maxAssociations="6" shuffle="false">
      <table style="width:95%;" v-if="questions.length">
        <thead>
        <tr>
          <th></th>
          <template v-for="choice in ['A','B','C','D','E','F']">
            <th><p><strong>{{choice}}</strong></p></th>
          </template>
        </tr>
        </thead>
        <tbody>
        <template v-for="q in questions">
          <tr style="height: 29px;" :key="q.id" @click="changeQuest(q.id)">
            <td :class="{'inFocus' : focusQuestNo === q.id}">
              <span class="questionNumber">{{q.id}}</span>
              <p>{{q.content}}</p></td>
              <template v-for="choice in ['A','B','C','D','E','F']">
                <td tabindex="0" @focus="changeQuest(q.id)" @click="() => q.answer = choice" :class="{'inFocus' : focusQuestNo === q.id, 'checked' : q.answer === choice}">
                  <input class="simpleAssociableChoice associableChoice choice" style="display:none" type="checkbox" /> 
                </td>
              </template>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  name: "matchingInformation",
  mixins: [common],
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
}
</script>

<style scoped>

</style>
