<template>
  <div author-class="container generic">
    <p author-class="subRubric cbview" v-html="part.note"/>

    <div author-class="container accordionChoice" class="question">
      <div class="questionChoice ui-accordion ui-widget ui-helper-reset" role="tablist">
        <template v-for="q in questions">
          <h3 @click="changeQuest(q.id)"
              @focus="changeQuest(q.id)"
              :id=q.id
              @mouseover="hover(q.id)"
              @mouseout="out(q.id)"
              class="interaction ui-accordion-header ui-helper-reset ui-state-default ui-corner-all"
               role="tab"
              :class="{'inFocus' : focusQuestNo === q.id}"
              :aria-selected="focusQuestNo === q.id" :aria-expanded="focusQuestNo === q.id"><span
              class="questionNumber">{{ q.id }}</span>
            <p class="prompt" style="padding: 0 0 0 25px">{{ q.content }}</p>
          </h3>
          <div hasPrompt="true" maxChoices="1"
               class="choiceInteraction blockInteraction interaction ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom"
               role="tabpanel" :aria-hidden="focusQuestNo !== q.id">
            <ul shuffle="false">
              <template v-for="c in q.choice">
                <li class="simpleChoice choice">
                  <label>
                    <input type="radio" v-model="q.answer" :value="c.label"/>
                    <p>{{ c.label }}</p>
                  </label>
                </li>
              </template>
            </ul>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  name: "oneChoice",
  mixins: [common],
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
  methods: {
    hover(id) {
      const element = document.getElementById(id)
      if (element) {
        let clazz = element.className
        if (!clazz.includes("ui-state-hover")) element.className += " ui-state-hover"
      }
    },
    out(id) {
      const element = document.getElementById(id)
      if (element) {
        let clazz = element.className
        clazz = clazz.replace(" ui-state-hover", "")
        element.className = clazz
      }
    },
  }
}
</script>

<style>
h3 + div[role="tabpanel"] {
  -webkit-transition: max-height .3s;
  -moz-transition: max-height .3s;
  -ms-transition: max-height .3s;
  -o-transition: max-height .3s;
  transition: max-height .3s;
  max-height: 0;
}

h3.inFocus + div[role="tabpanel"] {
  -webkit-transition: max-height .7s;
  -moz-transition: max-height .7s;
  -ms-transition: max-height .7s;
  -o-transition: max-height .7s;
  transition: max-height .7s;
  max-height: 200px;
}
</style>
