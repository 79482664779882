<template>
  <div author-class="right" class="scroll-container">
    <keep-alive>
      <component
          :is="part.type"
          :focus-quest-no="focusQuestNo"
          :part="part"
          :questions="questions"/>
    </keep-alive>
  </div>
</template>

<script>
import MatchingInformation from "@/views/apps/academic-reading/components/matchingInformation";
import TrueFalseNotGiven from "@/views/apps/academic-reading/components/trueFalseNotGiven";
import NoteCompletion from "@/views/apps/academic-reading/components/noteCompletion";
import SentenceCompletion from "@/views/apps/academic-reading/components/sentenceCompletion";
import MatchingFeatures from "@/views/apps/academic-reading/components/matchingFeatures";
import MultipleChoice from "@/views/apps/academic-reading/components/multipleChoice";
import SummaryCompletion from "@/views/apps/academic-reading/components/summaryCompletion";
import OneChoice from "@/views/apps/academic-reading/components/oneChoice";
import SummaryCompletionDrag from "@/views/apps/academic-reading/components/summaryCompletionDrag";
import MatchingHeading from "@/views/apps/academic-reading/components/matchingHeading";
import MatchingSentenceEndings from "@/views/apps/academic-reading/components/matchingSentenceEnding";
import FlowchartCompletion from "@/views/apps/academic-reading/components/flowchartCompletion";
export default {
  name: "PartRight",
  components: {
    FlowchartCompletion, MatchingSentenceEndings, SummaryCompletionDrag, OneChoice, SummaryCompletion, MatchingHeading,
    MultipleChoice, MatchingFeatures, SentenceCompletion, NoteCompletion, TrueFalseNotGiven, MatchingInformation
  },
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
          part_content: ''
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default() {
        return 1;
      }
    }
  },
}
</script>

<style scoped>

</style>
